<template>
    <transition name="fade">
        <div class="cdids" v-if="isDidOpen">
            <div class="out" @click="() => {this.$parent.didOpen = false}"> </div>
            <div class="title">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00195 7.63027C5.00195 7.2989 5.27058 7.03027 5.60195 7.03027H18.4059C18.7372 7.03027 19.0059 7.2989 19.0059 7.63027V16.462C19.0059 16.7934 18.7372 17.062 18.4059 17.062H13.4413C13.2029 17.062 12.9876 16.9226 12.8854 16.7072C12.4625 15.815 11.2633 13.4465 9.81567 12C8.39582 10.5813 6.24338 9.55638 5.37987 9.17985C5.15312 9.08097 5.00195 8.85869 5.00195 8.61131V7.63027Z" fill="currentColor"/> </svg>
                <span>
                    Select which device you'd like to play this on.
                </span>
            </div>

            <div class="dids">

                <div class="did-wrapper" v-if="vodLocal">
                    <div class="did local" @click="setVodLocal">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M7 21L17 21" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16.4V3.6C2 3.26863 2.26863 3 2.6 3H21.4C21.7314 3 22 3.26863 22 3.6V16.4C22 16.7314 21.7314 17 21.4 17H2.6C2.26863 17 2 16.7314 2 16.4Z" stroke="#000" stroke-width="1.5"/> </svg>
                            <p> Watch Here </p>
                        </div>
                        <i> ON DEVICE </i>
                    </div>

                    <button v-ripple @click="setVodDownload"> <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none"> <path d="M6 20L18 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> </button>
                </div>


                <div class="did local" @click="setLiveLocal" v-if="liveLocal">
                    <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M7 21L17 21" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2 16.4V3.6C2 3.26863 2.26863 3 2.6 3H21.4C21.7314 3 22 3.26863 22 3.6V16.4C22 16.7314 21.7314 17 21.4 17H2.6C2.26863 17 2 16.7314 2 16.4Z" stroke="#000" stroke-width="1.5"/> </svg>
                        <p> Watch Here </p>
                    </div>
                    <i> ON DEVICE </i>
                </div>

                <div class="did" v-for="(d,i) in dids" :key="i" @click.stop="setDid(d)">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6 17L3 17L3 4L21 4L21 17L18 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M8.62188 19.0672L11.5008 14.7488C11.7383 14.3926 12.2617 14.3926 12.4992 14.7488L15.3781 19.0672C15.6439 19.4659 15.3581 20 14.8789 20H9.12111C8.64189 20 8.35606 19.4659 8.62188 19.0672Z" stroke="currentColor" stroke-width="1.5"/> </svg>
                        <p> {{d.name}} </p>
                    </div>
                    <i> {{d.id}} </i>
                </div>
            </div>
        </div>

    </transition>
</template>

<script>

export default {
  name: 'collectDid',

    props: {
        isDidOpen: {
            required: true,
            type: Boolean,
            default: function(){
                return false
            }
        }

    },

  components: {
  },

  data: function(){
    return {
        dids: [],
    }
  },

  watch: { },

  computed: {
    vodLocal() {
      return this.$route.path.includes('/tv/') || this.$route.path.includes('/movie/');
    },
    liveLocal() {
      return this.$route.path.includes('/sport/') || this.$route.path.includes('/guide/') || this.$route.path.includes('/channel/');
    }
  },

  methods: {
        setVodLocal(){ this.$root.$emit('wod', 'vod') },
        setVodDownload(){ this.$root.$emit('wod', 'vod-save') },

        setLiveLocal(){
            this.$root.$emit('wod', 'live')
        },

        setDid(d){
            this.$root.$emit('newDid', d.id)
            this.$parent.didCallback(this.$parent.didParams)
            this.$parent.didOpen = false
        }
  },

  beforeDestroy: function(){
  },

  mounted: async function(){
    let req = await fetch(`${process.env.VUE_APP_SERVER}/dids?qb=${window.qb}`)
    let data = await req.json()
    this.dids = data.dids
  }

}
</script>

<style scoped lang="scss">
.cdids{
    position: fixed;
    top: calc(50vh - 200px);
    left: 5vw;
    width: 90vw;
    background-color: #dfe6e9;
    border-radius: 5px;
    z-index: 99999;
    .out{
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        opacity: 0.8;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
    display: flex;
    flex-direction: column;
    .title{
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        span{
            text-align: center;
        }
        svg{
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .dids{
        padding: 20px 0;
    }
    .did-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(80% + 10px);
        margin: 0 auto;
        button{
            border: 1px solid #0984e3;
            background-color: rgba(#0984e3, 0.45);
            outline: none;
            height: 100%;
            padding: 0 15px;
            margin: 0;
            min-height: 51px;
            max-height: 51px;
            border-radius: 5px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            // margin-left: 17.5px;
        }
        > .did{
            width: 100%;
            flex-grow: 1;
            margin: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .did{
        background-color: black;
        padding: 0 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        margin: 15px auto;
        &.local{
            margin-bottom: 20px;
            color: black;
            background-color: #fff;
        }
        >div{
            display: flex;
            align-items: center;
        }
        i{
            font-size: 9px;
            padding-right: 10px;
            font-style: normal;
            color:#2d3436;
            font-weight: bold;
        }

        svg{
            padding: 0 10px;
            padding-right: 15px;
        }
    }
}
</style>
